import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter } from /* webpackChunkName: "ReactRouterDom", webpackPrefetch: true */ "react-router-dom";
import { GA, Keys, Secrets, getEnv } from "./AppConfig";
import Routes from "./AppRoutes";
import theme from "./AppTheme";
import { Language, changeLanguage, getCurrentLanguage, languages } from "./i18n";
import { IpLocation, UTM, WorkerActions } from "./types.d";

function ErrorBoundary({ children }: { children: React.ReactNode }) {
	const [hasError, _] = React.useState(false);
	React.useEffect(() => {
		if (hasError) {
			const timeout = setTimeout(() => {
				window.location.reload();
			}, 3000);
			return () => clearTimeout(timeout);
		}
		return () => {};
	}, [hasError]);
	if (hasError) {
		return (
			<div className="flex flex-col items-center justify-center h-screen">
				<h1 className="text-2xl font-bold text-center">Something went wrong.</h1>
				<p className="text-sm text-center">Please wait, we will reload the page in 3 seconds.</p>
			</div>
		);
	}
	return children;
}

function App() {
	const [initLanguage, setInitLanguage] = React.useState(false);
	const appWorker = new Worker(new URL("./App.worker.ts", import.meta.url), { type: "module" });

	// Init i18n and save UTM params
	React.useEffect(() => {
		const exactMatch = languages.find((lang: Language) => lang.value === navigator.language) as Language;
		const broadMatch = languages.find(
			(lang: Language) => lang.value?.split("-")[0] === navigator.language.split("-")[0]
		) as Language;

		const pathSegments = window?.location?.pathname?.split("/")?.filter((p) => p);
		const pathMatch = languages.find((lang: Language) => lang.path === `/${pathSegments[0]}`) as Language;
		const bestMatchLanguage = pathMatch || exactMatch || broadMatch || getCurrentLanguage();
		if (!initLanguage) {
			setInitLanguage(true);
			changeLanguage(bestMatchLanguage.value);
		}

		const urlParams = new URLSearchParams(window.location.search);
		const utm = {
			utm_source: urlParams.get("utm_source") || "",
			utm_medium: urlParams.get("utm_medium") || "",
			utm_campaign: urlParams.get("utm_campaign") || "",
			utm_term: urlParams.get("utm_term") || "",
			utm_content: urlParams.get("utm_content") || "",
			search: window.location.search || "",
			referring_site: document.referrer || ""
		} as UTM;
		if (Object.values(utm).some((value) => value !== "")) {
			sessionStorage.setItem(Keys.utm, JSON.stringify(utm));
		}
	}, []);

	// Init non-blocking requests
	React.useEffect(() => {
		const timeoutGetIpLocation = setTimeout(async () => {
			try {
				// 1. Fetch IP Location off the main thread
				appWorker.postMessage({ action: WorkerActions.FETCH_IP_LOCATION, env: getEnv() });

				// 2. Google Tag Manager
				if (typeof window.gtag === "undefined") {
					const gtagScript = document.createElement("script");
					gtagScript.async = true;
					gtagScript.defer = true;
					gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA.trackingId}`;
					document.head.appendChild(gtagScript);
					const gtagConfigScript = document.createElement("script");
					gtagConfigScript.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments);}; gtag("js", new Date()); gtag("config", "${GA.trackingId}", ${JSON.stringify({ debug_mode: GA.debug })}); gtag("config", "${GA.containerTag}"); gtag("config", "${GA.adsConversionTracking}");`;
					document.head.appendChild(gtagConfigScript);
				}
				// 3. Posthog Web Analytics
				if (typeof window.posthog === "undefined") {
					const posthogScript = document.createElement("script");
					posthogScript.async = true;
					posthogScript.defer = true;
					posthogScript.innerHTML = `!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]); posthog.init("${Secrets.posthog}", {api_host: "https://app.posthog.com"});`;
					document.head.appendChild(posthogScript);
				}
			} catch (err: unknown) {
				sessionStorage.setItem(Keys.cc, JSON.stringify({}));
			}
		}, 3000); // delay 3s, to avoid degrade LCP
		return () => clearTimeout(timeoutGetIpLocation);
	}, []);

	React.useEffect(() => {
		appWorker.addEventListener("message", (event) => {
			const { action, data } = event.data as { action: string; data: unknown };
			switch (action) {
				case "fetchIpLocationData": {
					const fetchIpLocationData = data as IpLocation;
					if (fetchIpLocationData?.countryCode !== "") {
						sessionStorage.setItem(Keys.cc, JSON.stringify(data));
					}
					break;
				}
				case "initScripts":
					break;
				default:
					// eslint-disable-next-line no-console
					console.warn(`Unrecognized action: ${action}`);
					break;
			}
		});
		return () => appWorker.terminate();
	}, []);

	return (
		<ErrorBoundary>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<Routes />
				</ThemeProvider>
			</BrowserRouter>
		</ErrorBoundary>
	);
}

export default App;
