/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from "react";

declare module "*.scss" {
	const content: { [className: string]: string };
	export default content;
}

export enum Environments {
	UAT = "uat",
	TEST = "test",
	LOCALHOST = "localhost",
	PRODUCTION = "production",
	DEVELOPMENT = "development"
}

export enum WorkerActions {
	ERROR = "error",
	FETCH_IP_LOCATION = "fetchIpLocation",
	FETCH_IP_LOCATION_SUCCESS = "fetchIpLocationSuccess",
	INIT_GTAG = "initGtag",
	INIT_GTAG_SUCCESS = "initGtagSuccess"
}

// eslint-disable-next-line unused-imports/no-unused-vars
export type Route<T = string> = string;
export type PathName = Route<string>;

export interface User {
	id?: string;
	name: string;
	email: string;
	sessionId?: string;
}

export interface RouteConfig {
	title?: string;
	footer?: boolean;
	keywords?: string;
	toolbar?: boolean;
}

export interface Page extends RouteConfig {
	path: PathName;
	mobile?: RouteConfig;
	element: ComponentType<RouteConfig>;
	provider?: ComponentType<any> | null;
}

export interface HashPage {
	hash: string;
	element: ComponentType<RouteConfig>;
}

export interface RouteComponentProps {
	element: ComponentType<RouteConfig>;
	provider?: ComponentType<any> | null;
}

export interface IpLocation {
	city?: string;
	state?: string;
	country?: string;
	countryCode?: string;
}

export interface UTM {
	utm_source?: string;
	utm_medium?: string;
	utm_campaign?: string;
	utm_term?: string;
	utm_content?: string;
	referring_site?: string;
	search?: string;
}

export interface Recommendation {
	_id: string;
	sub: string;
	cta?: string;
	logo: string;
	name: string;
	sponsored?: boolean;
	description: string;
}
