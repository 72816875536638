import React from "react";

export default {
	/** Pages */
	Blog: React.lazy(() => import(/* webpackChunkName: 'blog' */ "./app/blog/blog")),
	NotFound: React.lazy(() => import(/* webpackChunkName: '404' */ "./app/404/404")),
	Upgrade: React.lazy(() => import(/* webpackChunkName: 'upgrade' */ "./app/upgrade/upgrade")),
	Download: React.lazy(() => import(/* webpackChunkName: 'download' */ "./app/download/page")),
	Recommendations: React.lazy(
		() => import(/* webpackChunkName: 'recommendations' */ "./app/recommendations/recommendations")
	),
	/** Partial Views / Containers */
	Footer: React.lazy(() => import(/* webpackChunkName: 'footer' */ "./containers/Footer/Footer")),
	Toolbar: React.lazy(() => import(/* webpackChunkName: 'toolbar' */ "./containers/Toolbar/Toolbar")),
	Intercom: React.lazy(() => import(/* webpackChunkName: 'intercom' */ "./containers/Intercom/Intercom")),
	RequestInfo: React.lazy(() => import(/* webpackChunkName: 'request-info' */ "./app/@partials/request-info")),
	PromotionalPopup: React.lazy(
		() => import(/* webpackChunkName: 'promotional-popup' */ "./app/@partials/promotional-popup")
	),
	/** Legal Policies */
	TermsOfServicePopup: React.lazy(() =>
		import(/* webpackChunkName: 'terms-of-service' */ "./app/@partials/legal-policies").then((module) => ({
			default: module.TermsOfServicePopup as React.ComponentType
		}))
	),
	PrivacyPolicyPopup: React.lazy(() =>
		import(/* webpackChunkName: 'privacy-policy' */ "./app/@partials/legal-policies").then((module) => ({
			default: module.PrivacyPolicyPopup as React.ComponentType
		}))
	),
	CookiePolicyPopup: React.lazy(() =>
		import(/* webpackChunkName: 'cookie-policy' */ "./app/@partials/legal-policies").then((module) => ({
			default: module.CookiePolicyPopup as React.ComponentType
		}))
	)
};
