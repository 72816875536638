import Box from "@mui/material/Box";
import React from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Subscribe, { SubscribeCongratulationPage } from "./app/subscribe/subscribe";
import PageLoader from "./components/PageLoader/PageLoader";
import { HashLinks } from "./AppConfig";
import CSP from "./containers/CSP";
import { changeLanguage, getCurrentLanguage, languages } from "./i18n";
import { HashPage, Page, RouteComponentProps } from "./types";
import { trackPageView } from "./utils/analytics";
import Chunks from "./AppChunks";
import Session from "./utils/session";

export const rootPages: Page[] = [
	{ path: "/subscribe/completed", element: SubscribeCongratulationPage },
	{ path: "/recommendations", element: Chunks.Recommendations, footer: false },
	{ path: "/subscribe", element: Subscribe },
	{ path: "/not-found", element: Chunks.NotFound, toolbar: false, footer: false },
	{ path: "/download", element: Chunks.Download },
	{ path: "/upgrade", element: Chunks.Upgrade },
	{ path: "/blog/:slug", element: Chunks.Blog },
	{ path: "/blog", element: Chunks.Blog },
	{ path: "/404", element: Chunks.NotFound, footer: false },
	{ path: "/", element: Subscribe }
];

export const hashPartials: HashPage[] = [
	{ hash: HashLinks.reqInfo, element: Chunks.RequestInfo },
	{ hash: HashLinks.promoPopup, element: Chunks.PromotionalPopup },
	{ hash: HashLinks.cookiePolicy, element: Chunks.CookiePolicyPopup },
	{ hash: HashLinks.privacyPolicy, element: Chunks.PrivacyPolicyPopup },
	{ hash: HashLinks.termsOfService, element: Chunks.TermsOfServicePopup }
];

const languagePages = languages
	.map((language) => {
		return rootPages.map(({ path: pagePath, element, provider, footer }) => {
			return {
				footer,
				element,
				provider,
				language,
				path: `${language.path}${pagePath}`.replace(/\/$/, "") // remove trailing slash
			};
		});
	})
	.flat();

const allPages = [...languagePages, ...rootPages];

function buildRefAlternateUrl(pathname: string, language: { path: string; value: string }) {
	const pathSegments = pathname.split("/").filter((p) => p);
	const browserLanguageCode = languages.some((lang) => lang.path === `/${pathSegments[0]}`);
	if (browserLanguageCode) {
		return `${window.location.origin}${language.path}/${pathSegments.slice(1).join("/")}`;
	}
	return `${window.location.origin}${language.path}${pathname}`;
}

function createAlternateLink(language: { path: string; value: string }) {
	return (
		<link
			rel="alternate"
			hrefLang={language.value}
			key={`alternate-${language.value}`}
			href={buildRefAlternateUrl(window.location.pathname, language)}
		/>
	);
}

function getCurrentRoute(path: string): Page | undefined {
	const pathname = path.replace(/\/$/, "");
	return allPages.find((page) => page.path === pathname);
}

function renderPartialPage(hash: string): React.ReactElement | null {
	const partial = hashPartials.find((p) => p.hash === hash);
	if (partial) {
		return React.createElement(partial.element);
	}
	return null;
}

function LazyFooterWrapper() {
	const [isVisible, setIsVisible] = React.useState(false);
	const footerRef = React.useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.unobserve(footerRef.current as HTMLDivElement);
				}
			},
			{
				rootMargin: "4px"
			}
		);

		if (footerRef.current) {
			observer.observe(footerRef.current);
		}

		return () => {
			if (footerRef.current) {
				observer.disconnect();
			}
		};
	}, []);

	return (
		<div ref={footerRef}>
			{isVisible && (
				<React.Suspense fallback={<div>Loading Footer...</div>}>
					<Chunks.Footer />
				</React.Suspense>
			)}
		</div>
	);
}

const RouteComponent = React.memo(({ element: Element, provider: Provider }: RouteComponentProps) => {
	return Provider ? (
		<Provider>
			<Element />
		</Provider>
	) : (
		<Element />
	);
});

function AppRoutes() {
	const location = useLocation();
	const user = Session.getUser();
	const language = getCurrentLanguage();
	const currentRoute = getCurrentRoute(location.pathname);

	React.useEffect(() => {
		trackPageView(`${location.pathname}${location.search}${location.hash}`, document.title);
	}, [location.pathname, location.search, location.hash]);

	React.useEffect(() => {
		const pathSegments = location.pathname.split("/").filter((p) => p);
		if (pathSegments.length > 0) {
			const languagePrefix = pathSegments[0];
			const matchingLanguage = languages.find((lang) => lang.path === `/${languagePrefix}`);
			if (matchingLanguage && language !== matchingLanguage) {
				changeLanguage(matchingLanguage.value);
			}
		}
	}, [location.pathname]);

	React.useEffect(() => {
		const shouldShowPopup = !location.pathname
			.split("/")
			.some((segment) => ["completed", "upgrade", "recommendations"].includes(segment));

		if (shouldShowPopup) {
			const timeout = setTimeout(
				() => {
					window.location.hash = HashLinks.promoPopup;
				},
				1 * 60 * 1000 // 1 minutes
			);
			return () => clearTimeout(timeout);
		}
		return () => {};
	}, [location.pathname, language?.path]);

	return (
		<React.Suspense fallback={<PageLoader />}>
			<Helmet>
				<CSP />
				{languages?.map((l) => createAlternateLink(l))}
				<html lang={language?.shortCode.toLocaleLowerCase()} />
			</Helmet>
			<Box id="Routes">
				{currentRoute?.toolbar !== false && <Chunks.Toolbar />}
				<Box
					id="RoutesContent"
					mt={currentRoute?.toolbar !== false ? "64px" : "0"}
				>
					<Routes>
						{[...allPages].map(({ element: Element, provider: Provider, path }: Page) => (
							<Route
								path={path}
								key={`page-${path}`}
								element={
									<RouteComponent
										element={Element}
										provider={Provider}
									/>
								}
							/>
						))}
						<Route
							path="*"
							element={<Navigate to="/404" />}
						/>
					</Routes>
				</Box>
				{currentRoute?.footer !== false && <LazyFooterWrapper />}
			</Box>
			{renderPartialPage(location.hash)}
			{user?.name && <Chunks.Intercom {...user} />}
		</React.Suspense>
	);
}

export default AppRoutes;
