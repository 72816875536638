import { nanoid } from /* webpackChunkName: "nanoid" */ "nanoid";
import { Keys } from "../AppConfig";
import { User } from "../types.d";

interface EncryptDecryptService {
	encrypt: (data: string) => string;
	decrypt: (encryptedData: string) => string;
}

class SimpleEncryptDecryptService implements EncryptDecryptService {
	private shift: number;

	constructor(shift: number = 1) {
		this.shift = shift;
	}

	encrypt(data: string): string {
		return data
			.split("")
			.map((char) => String.fromCharCode(char.charCodeAt(0) + this.shift))
			.join("");
	}

	decrypt(encryptedData: string): string {
		return encryptedData
			.split("")
			.map((char) => String.fromCharCode(char.charCodeAt(0) - this.shift))
			.join("");
	}
}

class SessionUser {
	static setUser(user: User): void {
		const encryptedUser = new SimpleEncryptDecryptService().encrypt(JSON.stringify(user));
		sessionStorage.setItem(Keys.user, encryptedUser);
	}

	static getUser(): User {
		const encryptedUser = sessionStorage.getItem(Keys.user);
		if (!encryptedUser) {
			return {} as User;
		}
		const userJson = new SimpleEncryptDecryptService().decrypt(encryptedUser);
		return JSON.parse(userJson) as User;
	}

	static getUserName(): string {
		const user = this.getUser();
		if (!user.name) {
			throw new Error("User name is not available.");
		}
		return user.name;
	}

	static getUserEmail(): string {
		const user = this.getUser();
		if (!user.email) {
			throw new Error("User email is not available.");
		}
		return user.email;
	}

	static getUserId(): string | number {
		const user = this.getUser();
		if (!user.id) {
			throw new Error("User ID is not available.");
		}
		return user.id;
	}

	static clearUser(): void {
		sessionStorage.removeItem(Keys.user);
	}
}

class Session {
	static User = SessionUser;

	static setUser = SessionUser.setUser.bind(SessionUser);

	static getUser = SessionUser.getUser.bind(SessionUser);

	static clearUser = SessionUser.clearUser.bind(SessionUser);

	static getUserId = SessionUser.getUserId.bind(SessionUser);

	static getUserName = SessionUser.getUserName.bind(SessionUser);

	static getUserEmail = SessionUser.getUserEmail.bind(SessionUser);

	static getSessionId(this: void): string {
		let sessionId = sessionStorage.getItem(Keys.sessionId);
		if (!sessionId) {
			sessionId = nanoid(6);
			sessionStorage.setItem(Keys.sessionId, sessionId);
		}
		return sessionId;
	}
}

export default Session;
